import { render, staticRenderFns } from "./ExamItem.vue?vue&type=template&id=346b66df&scoped=true"
import script from "./ExamItem.vue?vue&type=script&lang=js"
export * from "./ExamItem.vue?vue&type=script&lang=js"
import style0 from "./ExamItem.vue?vue&type=style&index=0&id=346b66df&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "346b66df",
  null
  
)

export default component.exports